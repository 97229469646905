import React from "react"
require("./display.css")
const Display = () => {
  return (
    <div className="display">
      <h1>Car Accessories</h1>
      <div className="card">
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
        <div>
          <img src="https://picsum.photos/300" alt="" />
          <p>15 BEST RIFLE SCOPES OF 2020 – TOP RATED REVIEWS & COMPARISONS</p>
        </div>
      </div>
    </div>
  )
}

export default Display
